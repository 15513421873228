.nav {
  width:100%;
  padding:20px 40px;
  display:flex;
  position:absolute;
  top:0;
  left:0;
  transition: all 0.6s;

  @media screen and ( max-width: $tablet ) {
    padding:20px 20px;
  }

  .column-logo {
    a {
      img {
        width:auto;
        height: 60px;
        transition: all 0.2s;

        @media screen and ( max-width: $tablet ) {
          width:auto;
          height: 50px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .column-hamburger {
    display: none;
    flex-grow: 1;
    text-align: right;
    padding:8px 0px;
    @media screen and ( max-width: $tablet ) {
      display: block;
    }
  }

  .column-nav {
    flex-grow: 1;
    text-align: right;
    padding:20px 0px;

    @media screen and ( max-width: $tablet ) {
      display: none;
    }

    .page-link {
      font-weight: 700;
      font-size: 20px;
      margin-left: 40px;
      transition:all 0.15s;
      display:inline-block;

      &:hover {
        transform: scale(1.1);
      }
    }

    .social-media-wrapper {
      display: inline-block;
      .social-media-link {
        vertical-align: middle;
        margin-left: 10px;

        &:first-child {
          margin-left: 40px;
        }

        img {
          width: 30px;
          height: 30px;;
          transition:all 0.2s;
          
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    

  }

  .mobile-overlay{
    position:fixed;
    z-index: -100;
    padding:40px 40px;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color:$black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.3s;
    opacity:0;

    &.is-active {
      z-index: 999;
      opacity:1;
    }

    .MuiSvgIcon-root {
      position:absolute;
      right: 27px;
      top:26px;
    }

    .row {
      width: 100%;
      text-align: center;

      .logo {
        img {
          width: auto;
          height: 80px;
        }
      }
      .page-link {
        display: inline-block;
        color:$primary;
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 16px;
      }

      .social-media-link{
        margin: 10px 10px;
        img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}