.mint {
  .section-1 {
    padding: 140px 0px;

    .container {
      .mint-wrapper {
        width:100%;
        min-height: 500px;
        background-color:#FFFFFF;
        display:flex;
        padding:24px 48px;
        border-radius: 16px;
        border:2px solid $black;
        box-shadow: 6px 6px 0px rgba(0,0,0,1);
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and ( max-width: $tablet ){
          padding:48px 48px;
        }

        @media screen and ( max-width: $mobile ){
          padding:48px 24px;
        }


        .column-left {
          width:60%;
          max-width:420px;
          display:flex;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;

          @media screen and ( max-width: $tablet ){
            width:100%;
            max-width:none;
            order:1;
          }

          @media screen and ( max-width: $mobile ){
            width:100%;
            max-width:none;
            order:1;
          }

          .header-wrapper {
            width:100%;
            margin-bottom: 40px;
          }

          .action-wrapper {
            width:100%;
          }
        }

        .column-right {
          width:40%;
          display:flex;
          align-content: center;
          align-items: center;
          justify-content:center;

          @media screen and ( max-width: $tablet ){
            width:100%;
            order:0;
          }

          @media screen and ( max-width: $mobile ){
            width:100%;
            order:0;
          }

          .square {
            max-width:400px;
          }

        }
      }
    }
  }
}