.footer {
  width: 100%;
  background-color:$black;
  padding:90px 0px;

  @media screen and ( max-width: $tablet ){
    padding:70px 0px;
  }

  @media screen and ( max-width: $mobile ){
    padding:50px 0px;
  }

  .awards-wrapper {
    width:100%;
    display:flex;
    justify-content:space-between;
    flex-wrap: wrap;
    margin-bottom: 16px;

    img {
      width:25%;
      padding:0px 10px;

      @media screen and ( max-width: $tablet ){
        width:50%;
      }

      @media screen and ( max-width: $mobile ){
        width:50%;
      }
    }
  }

  .disclaimer {
    margin-bottom:60px;
  }


  .social-media-wrapper{
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 8px;

    a {
      width: 86px;
      display: inline-block;
      margin: 0 20px;

      @media screen and ( max-width: $tablet ){
        width: 64px;
      }

      @media screen and ( max-width: $mobile ){
        width: 48px;
      }

      img {
        width: 100%;
        height: auto;
        transition:all 0.3s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .logo-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    img {
      width: 160px;
      display: block;
      height: auto;
      margin: auto;

      @media screen and ( max-width: $tablet ){
        width: 120px;
      }

      @media screen and ( max-width: $mobile ){
        width: 80px;
      }


    }
  }

}