.home {
  background-color: $primary;

  .section-head {
    padding-top: 140px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/BG_Dickcatz.png");

    .row-1 {
      flex-grow: 1;
      position: relative;
      // overflow: hidden;

      .text-wrapper {

        h3 {
          width: 100%;
          text-align: center;
          font-size: 32px;
          font-weight: 900;
          color:$black;
          transition: all 0.6s;

          @media screen and ( max-width: $tablet ){
            font-size: 28px;
          }

          @media screen and ( max-width: $mobile ){
            font-size: 20px;
          }
        }

        h2 {
          width: 100%;
          text-align: center;
          font-size: 56px;
          color:$black;
          transition: all 0.6s;
          font-family: 'Poppins', sans-serif;
          font-weight:800;
          font-style: italic;
          letter-spacing: -0.1rem;

          @media screen and ( max-width: $tablet ){
            margin-top: 30px;
            font-size: 48px;
          }

          @media screen and ( max-width: $mobile ){
            margin-top: 20px;
            font-size: 24px;
          }

        }

        h1 {
          width: 100%;
          text-align: center;
          font-size: 200px;
          font-weight: 900;
          color:$black;
          transition: all 0.6s;
          font-family: 'Poppins', sans-serif;
          letter-spacing: -0.2rem;
          line-height: 12rem;


          @media screen and ( max-width: $tablet ){
            line-height: 9rem;
            font-size: 140px;
          }

          @media screen and ( max-width: $mobile ){
            line-height: 5rem;
            font-size: 72px;
          }
        }
      }

      .animation-wrapper{
        position:absolute;
        height: 0;
        left: 50%;
        transform: translate(-50%,0%);
        width: 40%;
        padding-bottom:40%;
        bottom: 0%;
        transition: all 0.6s;

        @media screen and ( max-width: $tablet ){
          width: 80%;
          padding-bottom:80%;
        }

        @media screen and ( max-width: $mobile ){
          width: 100%;
          padding-bottom:100%;
        }

        .square {
          position:absolute;
          width: 100%;
          height: 100%;
        }
      }


      
    }

    .row-2 {
      background-color:$black;
      height: 80px;
      padding:24px 0px;
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      position:relative;

      @keyframes scrollLeft {
        0%{
          left: 0;
        }
        100%{
          left: -956px;
        }
      }

      .text-wrapper{
        position:absolute;
        animation:10s scrollLeft infinite linear; 
        
        h3 {
          display: inline-block;
          padding: 0 20px;
          text-align: center;
          font-size: 32px;
          color:$primary;
          line-height: 32px;
          white-space: nowrap;
          font-family: 'Poppins', sans-serif;
          font-weight:800;
          
        }
      }
      
    }

  }

  .section-what-is-it {
    width: 100%;
    padding:0px 0px 80px 0px;

    background-image: url("../../assets/Graffiti_SuchaDickcat.png");
    background-position: bottom left;
    background-repeat: no-repeat;


    .container{
      display:flex;
      align-items: center;
      flex-wrap: wrap;

      .title-img {
        margin-left: 0;

        @media screen and ( max-width: $tablet ){
          margin-left: auto;
        }

        @media screen and ( max-width: $mobile ){
          margin-left: auto;
        }
      }

      .column-text {
        width:50%;

        @media screen and ( max-width: $tablet ){
          width:100%;
          order:1;
        }

        @media screen and ( max-width: $mobile ){
          width:100%;
          order:1;
        }

      }

      .column-image {
        width:50%;

        @media screen and ( max-width: $tablet ){
          width:100%;
          order:0;
          margin-bottom: 24px;
        }

        @media screen and ( max-width: $mobile ){
          width:100%;
          order:0;
          margin-bottom: 24px;
        }

        img {
          width:100%;
          max-width:400px;
          display:block;
          margin:auto;
        }

      }
    }
  }

  .section-time-unit-mint {
    width: 100%;
    padding:80px 0px;
    text-align: center;

    background-image: url("../../assets/Graffiti_Heart.png");
    background-position: top right;
    background-repeat: no-repeat;


    .counter-wrapper {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;

      .digital-wrapper {
        width: calc(25% - 20px);
        margin: 0 10px;
        max-width: 160px;
        border-radius:36px;
        background-color: $black;
        display: inline-block;
        padding:30px 10px;

        @media screen and ( max-width: $tablet ){
          margin: 0 10px;
          border-radius:24px;
        }

        @media screen and ( max-width: $mobile ){
          margin: 0 4px;
          border-radius:12px;
        }

        h3 {
          width: 100%;
          text-align: center;
          color: $primary;
          font-size: 88px;
          font-weight: 800;
          line-height: 88px;
          font-family: 'Poppins', sans-serif;

          @media screen and ( max-width: $tablet ){
            font-size: 48px;
            line-height: 48px;
          }

          @media screen and ( max-width: $mobile ){
            font-size: 32px;
            line-height: 32px;
          }
        }

        h4 {
          width: 100%;
          text-align: center;
          color: $primary;
          font-size: 32px;
          font-weight: 800;
          line-height: 32px;
          font-family: 'Poppins', sans-serif;

          @media screen and ( max-width: $tablet ){
            font-size: 24px;
            line-height: 24px;
          }

          @media screen and ( max-width: $mobile ){
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }

    a {
      padding: 16px 32px;
      background-color: $black;
      font-weight: 900;
      font-size: 24px;
      color:$primary;
      margin: 0 auto 0px auto;
      display: inline-block;
      border-radius: 6px;

      @media screen and ( max-width: $tablet ){
        font-size: 20px;
      }

      @media screen and ( max-width: $mobile ){
        font-size: 16px;
      }
    }
  }

  .section-mint-details {
    width: 100%;
    padding:80px 0px 0px 0px;
    text-align: center;

    background-image: url("../../assets/Graffiti_LFG.png");
    background-position: top left;
    background-repeat: no-repeat;


    .fee-and-supply {
      padding: 32px 16px;
      background-color: $black;
      margin: 0 auto 72px auto;
      display: inline-block;
      border-radius: 6px;
      
      .supply {
        display:inline-block;
        padding:0px 32px;
        border-right: 1px solid $primary;


        @media screen and ( max-width: $mobile ){
          border-right:0;
          padding:16px 32px;
          border-bottom: 1px solid $primary;
        }
      }

      .fee {
        display:inline-block;
        padding:0px 32px;
        border-left: 1px solid $primary;

        @media screen and ( max-width: $mobile ){
          padding:16px 32px;
          border-left:0;
        }
      }


    }

    
  }

  .section-slider {
    width: 100%;
    padding:200px 0px 80px 0px;

    background-image: url("../../assets/Graffiti_Gm.png");
    background-position: top right;
    background-repeat: no-repeat;

    .slider-wrapper {
      width: 100%;
      padding: 0 0px;

      .slick-slide {
        padding: 0px 5px;

        .image-wrapper {
          margin: 0 10px;
          
          .image {
            padding:20px 20px 10px 20px;
            background-color:$white;
            img {
              width: 100%;
              height: auto;
            }
          }

          .barcode {
            padding:10px 20px 15px 20px;
            background-color:$white;

            img {
              width: 100%;
              height: auto;
              margin-bottom: 8px;
            }

            h4 {
              margin-bottom:0;
            }

          }
        }
      }
      
    }
  }

  

  .section-why-mint {
    width: 100%;
    padding:80px 0px;
    text-align: center;

    background-image: url("../../assets/JizzFrame.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100% auto;


    .container {
      margin-bottom: 40px;
      .row {
        width: 100%;
        padding: 20px 32px;

        .column-image {
          display: inline-block;
          width: 30%;
          padding:0px 24px;

          @media screen and ( max-width: $tablet ){
            width: 30%;
          }

          @media screen and ( max-width: $mobile ){
            width: 100%;
          }

          img {
            width: 100%;
            height: auto;

            @media screen and ( max-width: $mobile ){
              width: 60%;
            }
          }
        }

        .column-text {
          display: inline-block;
          width: 60%;
          padding:32px 12px;
          vertical-align: top;

          @media screen and ( max-width: $tablet ){
            width: 70%;
          }

          @media screen and ( max-width: $mobile ){
            width: 100%;
          }

          a {
            font-weight: 700;
            text-decoration:underline;
            transition:all 0.2s;

            &:hover {
              color:$brown;
            }
          }

        }
      }

      hr {
        border-color: $black;
      }


    }

    a {
      margin: 0 auto 30px auto;
      display: inline-block;
    }

  }

  .section-team {
    width: 100%;
    padding:80px 0px;
    text-align: center;


    .team-wrapper {
      width: 100%;

      .container {
        text-align: center;

        .member-wrapper {
          display: inline-block;
          margin: 0 20px 40px 20px;
          width: calc(33.33% - 40px);
          vertical-align:top;


          @media screen and ( max-width: $tablet ){
            width: calc(50% - 40px);
          }

          @media screen and ( max-width: $mobile ){
            width: calc(100% - 40px);
          }

          img {
            width: 100%;
            height: auto;
            display: block;
            margin: auto;
            border-radius:50%;
            margin-bottom: 24px;

            @media screen and ( max-width: $mobile ){
              width: 60%;
            }
          }
        }
      }
    }
  }

}