body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $primary;
  color: $black;
  -webkit-font-smoothing: subpixel-antialiased !important; 
  text-rendering:optimizeLegibility !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div, input, textarea, select, a, ol, ul, h1, h2, h3, h4, h5, h6, nav, img, header, section, button{
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

a{
  text-decoration:none;
  font-size:inherit;
  color:inherit;
  font: inherit;
  font-family: 'Poppins', sans-serif;
}

.page-enter {
  opacity: 0;
}
.page-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.container {
  width:96%;
  max-width:1024px;
  margin: auto;
  transition: width 0.5s;

  @media screen and ( max-width: $tablet ) {
    width: 94%;
  }

  @media screen and ( max-width: $mobile ) {
    width: 90%;
    // padding-left:20px;
    // padding-right: 20px;
  }
}

.title-img {
  width:auto;
  display:block;
  margin: 0px auto 40px auto;
  height:80px;
  transition:all 0.3s;

  @media screen and ( max-width: $tablet ) {
    height:74px;
  }

  @media screen and ( max-width: $mobile ) {
    height:44px;
  }
}

.title-lg {
  font-size: 48px;
  line-height:1.5;
  font-weight: 900;
  letter-spacing: 0.05em;
  color:$black;
  text-align: left;
  transition:all 0.3s;
  font-family: 'Poppins', sans-serif;
  font-weight:800;

  @media screen and ( max-width: $tablet ) {
    font-size: 40px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 32px;
  }
}

.title-md {
  font-size: 26px;
  line-height:1.5;
  font-weight: 800;
  letter-spacing: 0.03em;
  color:$black;
  text-align: left;
  transition:all 0.3s;
  font-family: 'Poppins', sans-serif;
  font-weight:800;

  @media screen and ( max-width: $tablet ) {
    font-size: 26px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 24px;
  }
}

.title-sm {
  font-size: 28px;
  line-height:1.5;
  font-weight: 900;
  letter-spacing: 0.02em;
  color:$black;
  text-align: left;
  transition:all 0.3s;
  font-family: 'Poppins', sans-serif;
  font-weight:800;

  @media screen and ( max-width: $tablet ) {
    font-size: 24px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 20px;
  }
}

.text-lg {
  font-size: 20px;
  line-height:1.5;
  letter-spacing: 0em;
  color:$black;
  transition:all 0.3s;
  margin-top: 10px;
  margin-bottom: 24px;
  text-align: left;

  @media screen and ( max-width: $tablet ) {
    font-size: 20px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 20px;
  }
}

.text-md {
  font-size: 18px;
  line-height:1.5;
  letter-spacing: 0em;
  color:$black;
  transition:all 0.3s;
  margin-bottom: 18px;
  text-align: left;

  @media screen and ( max-width: $tablet ) {
    font-size: 18px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 18px;
  }
}

.text-sm {
  font-size: 14px;
  line-height:1.5;
  letter-spacing: 0.02em;
  color:$black;
  transition:all 0.3s;
  margin-bottom: 14px;
  text-align: left;

  @media screen and ( max-width: $tablet ) {
    font-size: 14px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 14px;
  }
}

.button-lg {
  padding: 16px 40px 10px 40px;
  background-color: $black;
  font-weight: 800;
  font-size: 48px;
  color:$primary;
  border-radius: 10px;
  transition:all 0.3s;

  @media screen and ( max-width: $tablet ) {
    font-size: 40px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 32px;
  }
}

.button-md {
  padding: 16px 40px 10px 40px;
  background-color: $black;
  font-weight: 800;
  font-size: 36px;
  color:$primary;
  border-radius: 10px;
  transition:all 0.3s;

  @media screen and ( max-width: $tablet ) {
    font-size: 30px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 24px;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.button-sm {
  padding: 16px 40px 10px 40px;
  background-color: $black;
  font-weight: 800;
  font-size: 24px;
  color:$primary;
  border-radius: 10px;
  transition:all 0.3s;

  @media screen and ( max-width: $tablet ) {
    font-size: 22px;
  }

  @media screen and ( max-width: $mobile ) {
    font-size: 20px;
  }
}

.color-pr {
  color:$primary;
}

.color-bl {
  color:$black;
}

.color-wh {
  color:$white;
}

.hover-br:hover {
  color:$brown;
}

.text-upper {
  text-transform:uppercase;
}

.text-pre-line {
  white-space: pre-line;
}

.text-center {
  text-align:center;
}