.roadmap {
  .section-1 {
    padding-top: 140px;
    
    .container {
      
    }
  }

  .section-phase {
    padding: 0px 0px 40px 0px;
    .container {
      padding: 0px 0px;
      hr {
        border-color: $black;
        margin: 0px auto 40px auto;
      }

      h2 {
        margin-bottom:24px;
      }

      .steps-wrapper {
        width:100%;
        max-width:700px;
        margin: auto;
        

        .step {
          display: flex;
          justify-content: flex-start;
          
          .icon {
            width:36px;
            position:relative;
            img {
              width:36px;
              height: 36px;
            }

            .stroke {
              width:4px;
              height:calc(100% - 36px);
              background-color:$black;
              position:absolute;
              top:36px;
              left:16px;
            }
          }

          .text {
            margin: 0px 0px 20px 30px;

            h3 {
              font-size: 22px;
              font-family: 'Poppins', sans-serif;
              font-weight: 800;

              @media screen and ( max-width: $tablet ){
                    font-size: 20px;
              }
            
              @media screen and ( max-width: $mobile ){
                    font-size: 18px;
              }
            }
          }
        }

        
      }
    }
  }
}