.licensing-agreement {
  .section-1 {
    padding: 140px 0px 80px 0px;

    .container {
      h1 {
        margin-bottom:24px;
      }
    }
  }
}