.faq {
  .section-1 {
    padding: 140px 0 80px 0;

    .container {
      h2 {
        width: 100%;
        text-align: center;
        font-size: 64px;
        color:$black;
        font-family: "Retroking";
        margin: 0 auto 32px auto;

        @media screen and ( max-width: $tablet ){
          font-size: 48px;
        }

        @media screen and ( max-width: $mobile ){
          font-size: 32px;
        }
      }

      .faq-wrapper {
        border-bottom:1px solid $black;

        .MuiAccordion-root {
          background-color: $primary;
          box-shadow:none;
          padding:8px 0;
          border-top:1px solid $black;

          .MuiAccordionSummary-root {

            .MuiAccordionSummary-content {
              h3 {
                color:$black;
                font-size: 26px;
                font-weight: 800;
                font-family: 'Poppins', sans-serif;

                @media screen and ( max-width: $tablet ){
                  font-size: 24px;
                }

                @media screen and ( max-width: $mobile ){
                  font-size: 20px;
                }
              }
            }

            .MuiIconButton-root{
              color:$black;
              font-size: 30px;
            }

          }

          .MuiCollapse-root {
            .MuiCollapse-wrapper{
              .MuiAccordionDetails-root{
                flex-wrap: wrap;
                p {
                  width: 100%;
                  color:$black;
                  font-size: 18px;
                  line-height:1.4rem;
                  margin-bottom: 8px;


                  @media screen and ( max-width: $tablet ){
                    font-size: 16px;
                  }

                  @media screen and ( max-width: $mobile ){
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

      }
    }
    
  }
}