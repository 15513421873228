.gallery {
  .section-1 {
    padding-top: 140px;

    .container {
      h2 {
        width: 100%;
        text-align: center;
        font-size: 64px;
        color:$black;
        font-family: "Retroking";

        @media screen and ( max-width: $tablet ){
          font-size: 48px;
        }

        @media screen and ( max-width: $mobile ){
          font-size: 32px;
        }
      }
    }
  }
}